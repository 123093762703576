
import { defineComponent, ref, reactive } from "vue";
import { useRouter } from "vue-router";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor";
import BalloonEditor from "@ckeditor/ckeditor5-editor-balloon/src/ballooneditor";
import DocumentEditor from "@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import StrikethroughPlugin from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import CodePlugin from "@ckeditor/ckeditor5-basic-styles/src/code";
import SubscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import SuperscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import EasyImage from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import HeadingButtonsUI from "@ckeditor/ckeditor5-heading/src/headingbuttonsui";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";

import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const router = useRouter();

    const dataKP = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
      exDataTopik: {
        topik: "",
        kategori: "",
        content: "",
      },
      listKategori: [],
    });

    const titleTable: Array<string> = ["Topik", "Kategori", "Tanggal", "#"];

    const getData = async () => {
      cash("#loadingKP").show();

      const hide = () => cash("#loadingKP").hide();
      const page = dataKP.page;
      const pageSize = dataKP.pageSize;
      const search = dataKP.search;

      const url =
        (await api.getConfigFeatureMember(
          "memberKelasPrivate",
          "featureKelasPrivate"
        )) +
        "?search=" +
        search +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize;
      const hitApi = await api.hitApiGet(url, hide);
      const ds = hitApi.data.diagnostic;
      const rs = hitApi.data.response;

      dataKP.data = rs;
      dataKP.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
    };
    getData();

    const getKategoriKelas = async () => {
      gpf.gLoading.show();

      const hide = () => gpf.gLoading.hide();
      const url = await api.getConfigFeatureMember(
        "memberKelasKategori",
        "featureKelasKategori"
      );
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;
      dataKP.listKategori = rs;
      dataKP.exDataTopik.kategori = rs[0].id;

      console.log("getKategoriKelas ", rs);

      gpf.showModal("#modal-add-topik-kp");
    };

    const tambahTopik = async () => {
      gpf.gLoading.show();

      const hide = () => gpf.gLoading.hide();
      const ex = dataKP.exDataTopik;
      const params = new URLSearchParams();
      params.append("title", ex.topik);
      params.append("idKategori", ex.kategori);
      params.append("content", ex.content);

      const url = await api.getConfigFeatureMember(
        "memberKelasPrivate",
        "featureKelasPrivate"
      );
      const hitApi = await api.hitApiPost(url, params, hide);
      gpf.hideModal("#modal-add-topik-kp");
      await gpf.handleSds(hitApi, "toast");
      await getData();

      ex.topik = "";
      ex.kategori = "";
      ex.content = "";
    };

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataKP.page = p;
      getData();
    };

    const nextPage = () => {
      dataKP.page = dataKP.page + 1;
      getData();
    };

    const beforePage = () => {
      dataKP.page = dataKP.page - 1;
      getData();
    };

    const lastPage = () => {
      dataKP.page = dataKP.paginate.totalPages;
      getData();
    };

    // BEGIN for CKEditor
    const date = ref("");
    const classicEditor = ClassicEditor;
    const inlineEditor = InlineEditor;
    const balloonEditor = BalloonEditor;
    const documentEditor = DocumentEditor;
    const simpleEditorConfig = {
      plugins: [
        ParagraphPlugin,
        BoldPlugin,
        UnderlinePlugin,
        ItalicPlugin,
        LinkPlugin,
      ],
      toolbar: {
        items: ["bold", "italic", "underline", "link"],
      },
    };
    const editorConfig = {
      cloudServices: {
        tokenUrl: "",
        uploadUrl: "",
      },
      plugins: [
        Font,
        EssentialsPlugin,
        BoldPlugin,
        UnderlinePlugin,
        StrikethroughPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        CodePlugin,
        SubscriptPlugin,
        SuperscriptPlugin,
        EasyImage,
        Heading,
        HeadingButtonsUI,
        Highlight,
      ],
      toolbar: {
        items: [
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "code",
          "subscript",
          "superscript",
          "link",
          "undo",
          "redo",
          "imageUpload",
          "highlight",
        ],
      },
    };
    const initDocumentEditor = (editor: any) => {
      const toolbarContainer = document.querySelector(
        ".document-editor__toolbar"
      );
      toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
      const win: any = window;
      win.editor = editor;
    };
    // END for CKEditor

    return {
      titleTable,
      dataKP,
      getData,
      router,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      getKategoriKelas,
      tambahTopik,

      // for CKEditor
      date,
      classicEditor,
      inlineEditor,
      balloonEditor,
      documentEditor,
      simpleEditorConfig,
      editorConfig,
      initDocumentEditor,
    };
  },
  data() {
    return {};
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.dataKP.exDataTopik;
      ex[f] = val;
    },
    detailKP(data: any) {
      this.$router.push("kelas-private/" + data.uuid);
    },
  },
});
